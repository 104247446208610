<div class="background" fpfLayout>
    <section class="app-container" fpfLayout fpfFlex>
        <header fpfLayout fpfFlex>
            <mat-toolbar id="header-vacancies">
                <img alt="" src="assets/images/logo-bit-vert.svg" height="32px" class="logo-bar">
            </mat-toolbar>
        </header>

        <section class="content-container" fpfLayout fpfFlex>
            <mat-card class="card-title" fpfLayout="column">
                <div class="bg-white">
                    <button mat-icon-button color="primary" (click)="goToPage('/vacancies')">
                        <mat-icon id="icon-style">arrow_back</mat-icon>
                    </button>
                </div>
                <div fpfLayout="column" fpfLayoutAlign="center center" fpfFlex="100%">
                    <div fpfLayout class="cards">
                        <mat-card-title fpfLayout fpfLayoutAlign="center start" class="function-title m-3">
                            {{ object?.function?.name }} ({{ object?.role_type?.name }})
                        </mat-card-title>
                        <div fpfLayout="column">
                            <mat-card-content>
                                <div fpfLayout="row">
                                    <div fpfLayout="row" fpfLayoutGap="2">
                                        <mat-icon>room</mat-icon>
                                        <p>Manaus-AM</p>
                                    </div>
                                    <div fpfLayout="row" fpfLayoutGap="2" class="icons-modality">
                                        @switch (object.job_modality){
                                            @case (job_mobility.REMOTE){
                                                <mat-icon>home</mat-icon>
                                            }
                                            @case (job_mobility.HYBRID){
                                                <mat-icon>transfer_within_a_station</mat-icon>
                                            }
                                            @case (job_mobility.WORK_IN_PERSON){
                                                <mat-icon>domain</mat-icon>
                                            }
                                        }
                                        <p>{{ object?.display_job_modality }}</p>
                                    </div>
                                </div>
                                <div fpfLayout="row">
                                    <mat-card-actions>
                                        <button mat-flat-button color="accent" class="text-white"
                                                (click)="goToPage('/login/')">
                                            Desejo me candidatar
                                        </button>
                                    </mat-card-actions>
                                </div>
                            </mat-card-content>
                        </div>
                    </div>
                </div>

            </mat-card>

            <div fpfLayout="column" fpfLayoutAlign="center center">
                <mat-card class="cards">
                    <mat-card-title class="m-3"> {{ 'job-description' | translate }}</mat-card-title>
                    <p [innerHtml]="object.description" class="m-3"></p>
                </mat-card>

                @if (vacancySkillsMandatory.length !== 0) {
                    <mat-card class="cards-chips" fpfLayout="column">
                        <mat-card-title class="m-3">{{ 'mandatory-requirements' | translate }}</mat-card-title>
                        <mat-card-content fpfLayout="row">
                            <mat-chip-listbox fpfLayout="row wrap" class="custom-chip-list">
                                @for (element of vacancySkillsMandatory; track element) {
                                    <mat-chip class="chip">
                                        <span class="text-white">{{ element.skill["name"] }}</span>
                                    </mat-chip>
                                }
                            </mat-chip-listbox>
                        </mat-card-content>
                    </mat-card>
                }

                @if (vacancySkillsDesirable.length !== 0) {
                    <mat-card class="cards-chips">
                        <mat-card-title class="m-3">{{ 'desirable-requirements' | translate }}</mat-card-title>
                        <mat-card-content fpfLayout="row">
                            <mat-chip-listbox fpfLayout="row wrap" class="custom-chip-list">
                                @for (element of vacancySkillsDesirable; track element) {
                                    <mat-chip class="chip">
                                        <span class="text-white">{{ element.skill["name"] }}</span>
                                    </mat-chip>
                                }
                            </mat-chip-listbox>
                        </mat-card-content>
                    </mat-card>
                }

                <mat-card class="cards">
                    <mat-card-title class="m-3"> {{ 'benefits' | translate }}</mat-card-title>
                    <p [innerHtml]="object.benefits" class="m-3"></p>
                </mat-card>

                <mat-card class="cards">
                    <mat-card-title class="m-3">
                        Sobre a FPF Tech
                    </mat-card-title>
                    <mat-card-content fpfLayout>
                        A FPFtech é um Instituto Pesquisa & Desenvolvimento e um dos maiores players do ecossistema da
                        região.
                        Desde 1998 atuamos focados em geração de soluções inovadoras, serviços e cases de sucessos
                        globais
                        nas áreas de Software, Hardware, Automação Industrial e Capacitação Tecnológica,
                        desenvolvendo
                        sob
                        medida em sinergia com diversas unidades de negócios e potencializando as oportunidades
                        para
                        agregar
                        valor aos nossos clientes, tudo isso com a colaboração de Pessoas Notáveis: os nossos Techers!
                    </mat-card-content>
                </mat-card>
            </div>


        </section>

        <footer class="footer">
            <img alt="Logo" src="assets/images/logo-fpf-branco.svg" height="40px"/>
            <br>
            <span> 2023 © FPF Tech - Todos os direitos reservados.</span>
            <p> Av. Danilo de Matos Areosa, 1170 - Distrito Industrial. CEP: 69075-351 Manaus - AM / Brasil</p>

            <div fpfLayout="row" fpfLayoutAlign="center center">
                <a href="https://www.instagram.com/fpf.tech/">
                    <img src="assets/images/icon-instagram-white.svg" alt="Instagram"
                         class="media-icons">
                </a>

                <a href="https://www.facebook.com/fpftech">
                    <img src="assets/images/icon-facebook-white.svg" alt="Facebook"
                         class="media-icons">
                </a>

                <a href="https://www.linkedin.com/company/fpf/">
                    <img src="assets/images/icon-linkedin.svg" alt="Linkedin"
                         class="media-icons">
                </a>

                <a href="https://www.youtube.com/channel/UCuQgGlz-lA4AH9cf3p0R3MQ?sub_confirmation=1">
                    <img src="assets/images/icon-youtube-white.svg" alt="Youtube"
                         class="media-icons">
                </a>
            </div>
        </footer>
    </section>
</div>


