<mat-toolbar class="mat-dialog-header">
    <span class="w-full">{{ "search" | translate }}</span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<div fpfLayout="column" fpfLayoutGap="2" class="h-full">
    <div fpfLayout="row" fpfLayoutGap="2" fpfLayoutAlign="stretch start" class="m-3">
        <mat-form-field fpfLayout fpfFlex>
            <mat-label>{{ 'skill' | translate }}</mat-label>

            <input [appAutocomplete]="skill" matInput>
            <app-autocomplete #skill
                              (changeEvent)="getSkills($event)"
                              [display]="'name'"
                              [id]="'id'"
                              [paginatedResult]="skills">
            </app-autocomplete>
            <app-autocomplete-control [for]="skill" [limit]="10" matSuffix></app-autocomplete-control>
        </mat-form-field>
        <button type="button" mat-mini-fab color="accent"
                matTooltip="{{'search' | translate}}"
                (click)="getDetailsUsers(skill.item)">
            <mat-icon>search</mat-icon>
        </button>
    </div>
    @if (show) {
        <div class="ml-3 min-h-30" fpfLayout="row" fpfLayoutAlign="`center` between">
            <h1><span>{{ 'search-result' | translate }}: </span></h1>
            <h2 fpfLayout fpfFlex="23%" fpfLayoutAlign="2" class="mr-3">
                <div fpfLayout="row" fpfLayoutGap="2" fpfLayoutAlign="center center">
                    <mat-icon class="curriculum-badge"> portrait</mat-icon>
                    <strong>{{ 'curriculum' | translate }} </strong>
                </div>
                <div fpfLayout="row" fpfLayoutGap="2" fpfLayoutAlign="center center">
                    <mat-icon class="mapping-badge"> perm_contact_calendar</mat-icon>
                    <strong>{{ 'mapping' | translate }} </strong>
                </div>
                <div fpfLayout="row" fpfLayoutGap="2" fpfLayoutAlign="center center">
                    <mat-icon class="courses-badge"> book</mat-icon>
                    <strong>{{ 'course'| translate }} </strong>
                </div>
            </h2>
        </div>
            @defer (when searchList.length) {
                @for (user of searchList; track user) {
                    <mat-card class="ml-3 mr-3 mat-card-search-resul">
                        <app-infinite-scroll [targets]="user"></app-infinite-scroll>
                    </mat-card>
                }
            } @placeholder {
                <div fpfLayout="row" fpfLayoutAlign="center center" fpfFlex>
                    <img ngSrc="assets/themes/loading.gif" width="100" height="100"/>
                </div>
            }
    } @else {
        <mat-card class="m-3 h-full mat-card-bit">
            <div fpfLayout="column" fpfLayoutGap="2" fpfLayoutAlign="center center">
                <img alt="" src="assets/images/bit-search.svg" height="116px">
                <strong class="text-2xl">{{ 'search-employee-by-skill' | translate }}</strong>
            </div>
        </mat-card>
    }
</div>

