import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";


const selector = `
  [fpfLayout.xs], [fpfLayout.sm], [fpfLayout.md],
  [fpfLayout.lg]
`;

@Directive({
    standalone: true,
  selector
})
export class FlexLayoutResponsiveDirective implements OnInit {

  @Input("fpfLayout.xs") layout_xl: string = "row";
  @Input("fpfLayout.sm") layout_sm: string = "row";
  @Input("fpfLayout.md") layout_md: string = "row";
  @Input("fpfLayout.lg") layout_lg: string = "row";


  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  public responsiveFpfLayout(layoutValues: { [key: string]: string }) {
    for (let [key, value] of Object.entries(layoutValues)) {
      if (value === "") continue;
      const screenSize = key.split(".")[1];
      this.renderer.addClass(this.el.nativeElement, `${screenSize}:flex-${value}`);
    }
  }

  ngOnInit() {
    const inputValues = {
      "fpfLayout.xs": this.layout_xl,
      "fpfLayout.sm": this.layout_sm,
      "fpfLayout.md": this.layout_md,
      "fpfLayout.lg": this.layout_lg,
    };
    this.responsiveFpfLayout(inputValues);
  }
}
