<mat-autocomplete [autoActiveFirstOption]="true" [displayWith]="_displayFn">
    @for (result of paginatedResult.results; track result) {
        <mat-option  [value]="result">
            <div fpfLayout="row" fpfLayoutGap="2" fpfLayoutAlign="center start">
                @if (image) {
                    <img class="border-round-2xl" [ngSrc]="result[image] ? result[image] : 'assets/images/bit-perfil.svg'"
                         alt="" width="32" height="32">
                }
                {{ displayValue(result) }}
            </div>
        </mat-option>
    }
    @if (paginatedResult.results?.length === 0) {
        <mat-option [disabled]="true" class="empty-option">
            {{ "does-not-contain-any-results" | translate }}
        </mat-option>
    }
</mat-autocomplete>
