import {Component, Inject, Injector} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../services/auth.service";

@Component({
    selector: "app-lgpd-term-dialog",
    templateUrl: "./lgpd-term-dialog.component.html",
    styleUrls: ["./lgpd-term-dialog.component.scss"]
})
export class LgpdTermDialogComponent {

    constructor(public injector: Injector,
                @Inject(MAT_DIALOG_DATA) public data: LgpdTermDialogComponent,
                public authService: AuthService,
                public dialogRef: MatDialogRef<LgpdTermDialogComponent>) {
    }
}
