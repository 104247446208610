import {Component, Injector, OnInit} from "@angular/core";
import {CustomValidators} from "../../../utilities/validator/custom-validators";
import {FormBuilder, UntypedFormGroup} from "@angular/forms";
import {TranslateService} from "../../../services/translate.service";
import {ToastService} from "../../../services/toast.service";
import {URLS} from "../../../app/app.urls";
import {User} from "../../../models/account/user";
import {takeUntil} from "rxjs/operators";
import {LgpdTermDialogComponent} from "../lgpd-term-dialog/lgpd-term-dialog.component";
import {BaseComponentDetailDirective, BaseDetailComponentOptions} from "../../base-component-detail.directive";

const BASE_OPTIONS: BaseDetailComponentOptions = {
    endpoint: URLS.USER,
};

@Component({
    selector: "app-create-user-candidate",
    templateUrl: "./create-user-candidate.component.html",
    styleUrls: ["./create-user-candidate.component.scss"]
})
export class CreateUserCandidateComponent extends BaseComponentDetailDirective<User> implements OnInit {
    public hide: boolean = true;

    constructor(public toast: ToastService,
                public formBuilder: FormBuilder,
                public translate: TranslateService,
                public injector: Injector,
    ) {
        super(injector, BASE_OPTIONS);
    }

    public createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            name: [null, CustomValidators.validFieldText],
            email: [null, CustomValidators.validEmail],
            username: [null, CustomValidators.required],
            password: [null, CustomValidators.compose([
                CustomValidators.required,
                CustomValidators.validPassword,
            ])],
            conf_password: [null, CustomValidators.validEqual("password")],
            checked_term: [null, CustomValidators.compose([
                CustomValidators.required,
                CustomValidators.booleanTrue])
            ],
        });
    }

    public openLgpdTermDialog(): void {
        this.dialog.open(
            LgpdTermDialogComponent, {
                width: "900px",
                data: {
                    email: this.v.email,
                    name: this.v.name
                }
            }
        );
    }

    public saveOrUpdate(): void {
        if (this.v.conf_password !== this.v.password) {
            return this.toast.error("user", "password-not-match");
        } else {
            const payload = {
                name: this.v.name,
                username: this.v.username,
                password: this.v.password,
                email: this.v.email,
                is_candidate: true,
                checked_term: true,
            };
            this.service.postFromListRoute("create_user_candidate", payload)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((): void => {
                    this.toast.success(this.translate._("success-title"), "created-user");
                    this.goToPage("login");
                });
        }
    }
}
