import {Curriculum} from "../levelup/curriculum";
import {RoleUser} from "../levelup/role-user";

export class User {
    url: string;
    id: number;
    username: string;
    password: string;
    name: string;
    desired_name: string;
    check_show_again: boolean;
    email: string;
    last_login: Date;
    admission_date: Date;
    birth_date: Date;
    is_active: boolean;
    is_superuser: boolean;
    is_staff: boolean;
    is_default: boolean;
    avatar_minio: string;
    is_ad_user: boolean;
    telegram: string;
    checked: boolean;
    disabled: boolean;
    registration: number;
    regime: string;
    is_candidate: boolean;
    group: string;
    checked_term: boolean;
    current_role: string;
    linkedin: string;
    github: string;
    lattes: string;
    added_lattes: Date;
    external: boolean;
    curriculum: Curriculum;
    readonly last_team: string;
    readonly start_date_team: Date | string;
    readonly end_date_team: Date | string;

    //extra fields
    role_user: RoleUser;
}
