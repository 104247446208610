export const URLS = {
    // auth
    ACCESS_TOKEN: "/api/account/token/",
    REFRESH_TOKEN: "/api/account/token/refresh/",
    AZURE: "/api/accounts/login/",
    USER: "/api/account/user/",
    GROUP: "/api/account/group/",
    USER_GROUP: "/api/account/account_user_group/",
    PERMISSION: "/api/account/permission/",
    CONTENT_TYPE: "/api/account/content_type/",
    MENU: "/api/account/menu/",
    MODULE_MENU: "/api/account/module_menu/",
    MODULE: "/api/account/module/",
    CODE: "/api/account/code/",

    // levelup
    FEEDBACK: "/api/levelup/feedback/",
    CATEGORY: "/api/levelup/category/",
    EVENT_MAPPING: "/api/levelup/event_mapping/",
    COURSE: "/api/levelup/course/",
    COURSE_USER: "/api/levelup/course_user/",
    FOUNDATION: "/api/levelup/foundation/",
    MAPPING: "/api/levelup/mapping/",
    MAPPING_ITEM: "/api/levelup/mapping_item/",
    MAPPING_LEVEL_DETAIL: "/api/levelup/mapping_level_detail/",
    SKILL: "/api/levelup/skill/",
    CURRICULUM: "/api/levelup/curriculum/",
    CURRICULUM_ITEM: "/api/levelup/curriculum_item/",
    LANGUAGE_ITEM: "/api/levelup/language_item/",
    SCHOOLING: "/api/levelup/schooling/",
    ROLE: "/api/levelup/role/",
    ROLE_USER: "/api/levelup/role_user/",
    FUNCTION_USER: "/api/levelup/function_user/",
    EVENT_PROGRESS: "/api/levelup/event_progress/",
    ROLE_LEVEL: "/api/levelup/role_level/",
    ROLE_LEVEL_EXPECTATION: "/api/levelup/role_level_expectation/",
    ROLE_COURSE: "/api/levelup/role_course/",
    ROLE_SKILL: "/api/levelup/role_skill/",
    ROLE_DETAILING: "/api/levelup/role_detailing/",
    EMPLOYEE: "/api/levelup/employee/",
    FAVORITE_SKILL: "/api/levelup/favorite_skill/",
    FUNCTION: "/api/levelup/function/",
    FUNCTION_SKILL: "/api/levelup/function_skill/",
    FUNCTION_COURSE: "/api/levelup/function_course/",
    DEVELOPMENT_PROGRAM: "/api/levelup/development_program/",
    INDIVIDUAL_DEVELOPMENT_PROGRAM: "/api/levelup/individual_development_program/",
    INTEREST_SKILL_GOAL: "/api/levelup/interest_skill_goal/",
    INTEREST_SKILL_GOAL_ARTIFACT: "/api/levelup/interest_skill_goal_artifact/",
    INTEREST_SKILL: "/api/levelup/interest_skill/",
    INTEREST_COURSE: "/api/levelup/interest_course/",
    NECESSARY_LIST: "/api/levelup/necessary_list/",
    NECESSARY_LIST_SKILL: "/api/levelup/necessary_list_item/",
    PROGRESS_INTEREST_SKILL_GOAL: "/api/levelup/progress_interest_skill_goal/",
    VACATION_SCHEDULE: "/api/levelup/vacation_schedule/",
    VW_MAPPING_VALUE: "/api/levelup/mapping_value/",

    // settings
    SETTINGS: "/api/bit/settings/",
    COMPANY: "/api/bit/company/",
    LOGOUT: "/api/bit/logout/",


    // purchases
    // PROJECTS: "/api/purchases/project/",
    SUPPLIER: "/api/purchases/supplier/",
    PURCHASE_ORDER: "/api/purchases/purchase_order/",
    PURCHASE_MODULE: "/api/purchases/module/",
    SURVEY: "/api/purchases/survey/",
    SURVEY_TYPE: "/api/purchases/survey_type/",
    QUESTION_SURVEY: "/api/purchases/question/",
    SURVEY_PARTICIPANT: "/api/purchases/survey_participant/",
    QUESTION_TYPE: "/api/purchases/question_type/",


    // restaurant
    WORK_REGIME: "/api/restaurant/work_regime/",
    MEAL: "/api/restaurant/meal/",
    MEAL_SCHEDULING: "/api/restaurant/meal_scheduling/",

    // rh
    BASE_RH: "/api/rh/",
    BASE_YEAR: "/api/rh/base_year/",
    PROJECT: "/api/rh/project/",
    SETTINGS_BASE_YEAR: "/api/rh/settings_base_year/",
    HOUR_DISTRIBUTION: "/api/rh/hour_distribution/",
    COLABORATOR: "/api/rh/colaborator/",
    CANDIDATE: "/api/rh/candidate/",
    FORMATION: "/api/rh/formation/",
    CANDIDATE_COMPETENCE: "/api/rh/candidate_competence/",
    PROFESSIONAL_EXPERIENCE: "/api/rh/professional_experience/",
    ABOUT_YOU: "/api/rh/about_you/",
    CANDIDATE_CURRICULUM: "/api/rh/candidate_curriculum/",
    AREA_OF_INTEREST: "/api/rh/area_of_interest/",
    LANGUAGE: "/api/rh/language/",
    CERTIFICATE: "/api/rh/certificate/",
    VACANCY: "/api/rh/vacancy/",
    VACANCY_SKILL: "/api/rh/vacancy_skill/",
    STATE: "/api/rh/state/",
    CITY: "/api/rh/city/",
    QUESTION: "/api/rh/question/",
    INTERVIEW: "/api/rh/interview/",
    CANDIDATE_VACANCY: "/api/rh/candidate_vacancy/",
    QUESTION_SCORE: "/api/rh/question_score/",
    INTERVIEW_INTERVIEWERS: "/api/rh/interview_interviewers/",
    QUESTION_INTERVIEW: "/api/rh/question_interview/",
    EMPLOYEE_PROJECT: "/api/rh/employee_project/",

    // labs
    EXPERIMENTATION: "/api/labs/experimentation/",
    EVIDENCE: "/api/labs/evidence/",
    BUDGET: "/api/labs/budget/",
    EXPERIMENT_EMPLOYEE: "/api/labs/experiment_employee/",
    ATTACHMENT: "/api/labs/attachment/",


    // basic-registration
    CUSTOMER: "/api/basic_registration/customer/",
    TEAM: "/api/basic_registration/team/",
    TEAM_TAG: "/api/basic_registration/team_tags/",
    TEAM_USER: "/api/basic_registration/team_user/",
    TEAM_PROJECT: "/api/basic_registration/team_project/",
    PROJECT_EMPLOYEE: "/api/basic_registration/project_employee/",
    PROJECT_EMPLOYEE_SKILL: "/api/basic_registration/project_employee_skill/",

    //patrimony
    FIELD: "/api/patrimony/field/",
    STOCK: "/api/patrimony/stock/",


    // patrimony
    PATRIMONY_CATEGORY: "/api/patrimony/patrimony_category/",
    PRODUCT: "/api/patrimony/product/",
    MOVEMENT: "/api/patrimony/movement/",
    ITEM: "/api/patrimony/movement_item/",
    STOCK_BY_PRODUCT: "/api/patrimony/movement_item/query_stock/",
    SKU: "/api/patrimony/sku/",
    FIELD_SKU: "/api/patrimony/field_sku/",
    SKU_USER: "/api/patrimony/sku_user/",


};

