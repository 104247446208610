import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: "app-infinite-scroll",
  templateUrl: "./infinite-scroll.component.html",
  styleUrl: "./infinite-scroll.component.scss"
})
export class InfiniteScrollComponent implements OnInit{
    @Input({required: true}) targets: object[] = [];
    @Output() loaded: EventEmitter<number> = new EventEmitter<number>();

    iconTargets = [
        { condition: "exists_on_curriculum", class: "mat-icon-check curriculum-badge", tooltip: "is-check", icon: "portrait" },
        { condition: "exists_on_mapping", class: "mat-icon-check mapping-badge", tooltip: "is-check", icon: "perm_contact_calendar" },
        { condition: "exists_on_course", class: "mat-icon-check courses-badge", tooltip: "is-check", icon: "book" }
    ];

    public ngOnInit(): void {
        this.loaded.emit(this.targets.length);
    }
}
