@if (for.matAutocomplete.isOpen) {
    <button mat-icon-button type="button"
            [matTooltip]="'previous-page' | translate"
            [matTooltipShowDelay]="1000"
            [disabled]="!for.paginatedResult.previous"
            (click)="previousPage($event)">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button type="button"
            [matTooltip]="'next-page' | translate"
            [matTooltipShowDelay]="1000"
            [disabled]="!for.paginatedResult.next"
            (click)="nextPage($event)">
        <mat-icon>chevron_right</mat-icon>
    </button>
} @else if (canShowClearButton) {
    <button mat-icon-button type="button"
            [matTooltip]="'clear' | translate"
            [matTooltipShowDelay]="1000"
            (click)="clear()">
        <mat-icon>close</mat-icon>
    </button>
}
@if (canShowComponentButton) {
    <button
        [disabled]="for?._disabled"
        [matTooltip]="'create-new' | translate"
        [matTooltipShowDelay]="1000"
        mat-icon-button type="button"
        (click)="openDialog($event)">
        <mat-icon>playlist_add</mat-icon>
    </button>
}
@if (canShowCreateButton) {
    <button mat-icon-button type="button"
            [matTooltip]="'save' | translate"
            [matTooltipShowDelay]="1000"
            (click)="create($event)">
        <mat-icon>save</mat-icon>
    </button>
} @else {
    <button id="autocomplete-down" mat-icon-button type="button"
            [matTooltip]="'open' | translate"
            [matTooltipShowDelay]="1000"
            (click)="openPopup($event)">
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
}
