import {Component, Injector, OnInit, ViewChild} from "@angular/core";
import {MainService} from "../main.service";
import {URLS} from "../../../../app/app.urls";
import {takeUntil} from "rxjs/operators";
import {PaginatedResult} from "../../../../dto/paginated-result";
import {Skill} from "../../../../models/levelup/skill";
import {User} from "../../../../models/account/user";
import {AutocompleteEvent} from "../../../../shared/autocomplete/autocomplete-control.component";
import {MatPaginator} from "@angular/material/paginator";
import {BaseComponentListDirective, BaseListComponentOptions} from "../../../base-component-list.directive";

const BASE_OPTIONS: BaseListComponentOptions = {
    pk: "id",
    searchOnInit: true,
    endpoint: URLS.SKILL,
};

interface Search {
    id: number
    name: string;
    avatar_minio: string;
    role_type: string;
    skill_name: string;
    exists_on_course: boolean;
    exists_on_curriculum: boolean;
    exists_on_mapping: boolean;
}

@Component({
    selector: "app-search-employee-by-skill-dialog",
    templateUrl: "./search-employee-by-skill-dialog.component.html",
    styleUrl: "./search-employee-by-skill-dialog.component.scss"
})
export class SearchEmployeeBySkillDialogComponent extends BaseComponentListDirective<Skill> implements OnInit {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    public displayedColumns: string[] = ["name", "mapping", "curriculum", "course"];
    public skills: PaginatedResult<Skill> = new PaginatedResult();
    public show: boolean = false;
    public searchList: Search[] = [];
    public user: User;

    constructor(public mainService: MainService,
                public injector: Injector,
    ) {
        super(injector, BASE_OPTIONS);
    }

    public getDetailsUsers(event?: AutocompleteEvent): void {
        this.service.clearParameter();
        this.show = true ? event["id"] !== undefined : false;
        if (this.show) {
            this.service.addParameter("skill", event["id"]);
        }
        this.service.getFromListRoute("details_skill")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response: Search[]) => {
                this.searchList = response;
            });
    }


    public getSkills(event: AutocompleteEvent): void {
        this.service.clearParameter();
        this.service.addParameter("limit", event.limit);
        this.service.addParameter("offset", event.offset);
        this.service.addParameter("name", event.searchText);
        this.service.addParameter("skill_area_isnull", false);
        this.service.getPaginated()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response: PaginatedResult<Skill>) => this.skills = response);
    }
}
